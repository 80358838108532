<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <v-card class="" elevation="0">
                    <v-card-title>
                    <h4>Notifications</h4>
                    </v-card-title>
                    <v-card-text>
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col
                                    cols="12" sm="6" md="4"
                                >
                                  <v-text-field
                                      v-model="search.title"
                                      label="Title"
                                      outlined
                                      dense
                                      clearable
                                  >
                                  </v-text-field>
                                </v-col>
                                <v-col
                                    cols="12" sm="6" md="3"
                                >
                                    <v-menu
                                        ref="menuDate"
                                        v-model="menuStartDate"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="search.start_date"
                                                label="View from date"
                                                readonly
                                                outlined
                                                dense
                                                clearable
                                                v-bind="attrs"
                                                @input="search.start_date = $event !== null ? $event : ''" 
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="search.start_date"
                                            outlined
                                            dense
                                            no-title
                                            @input="menuStartDate = false"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col
                                    cols="12" sm="6" md="3"
                                >
                                    <v-menu
                                        ref="menuDate"
                                        v-model="menuEndDate"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="search.end_date"
                                                label="View up to date"
                                                readonly
                                                outlined
                                                dense
                                                clearable
                                                v-bind="attrs"
                                                v-on="on"
                                                @input="search.end_date = $event !== null ? $event : ''" 
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="search.end_date"
                                            outlined
                                            dense
                                            no-title
                                            :min="search.start_date"
                                            @input="menuEndDate = false"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="2"
                                >
                                    <v-btn
                                        :loading="loading"
                                        class="btn btn-primary btn-block text-white"
                                        @click="getAllNotifications"
                                    >
                                     <i class="fa fa-search"></i> Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>

                      <div class="table-responsive" style="table-layout: fixed">
                        <v-skeleton-loader
                            type="table-thead"
                            v-if="loading">
                        </v-skeleton-loader>

                        <v-skeleton-loader
                            v-if="loading"
                            type="table-row-divider@25">
                        </v-skeleton-loader>

                        <table class="table">
                          <thead>
                          <tr class="px-3">
                            <th class="px-3">Title</th>
                            <th class="px-3">Type</th>
                            <th class="px-3">Date/Time</th>
                            <th class="pr-3 text-center">Options</th>
                          </tr>
                          </thead>
                          <tbody>
                          <template>
                            <tr v-bind:class="{ 'notification-unread-main' : !item.is_viewed, 'notification-read-main' : item.is_viewed }" v-for="(item, index) in notifications" :key="index">
                              <td class="px-2">
                                <a v-if="item.type=='export' || item.type=='import'" :href="item.url"> {{item.title}} </a>
                                <a v-else-if="item.type=='print'" href="#" @click="viewNotification(item.id, item.type)"> {{item.title}} </a>
                                <a v-else-if="item.type == 'note' " @click="emailPreview(item)"> {{item.title}} </a>
                                <a v-else>{{item.title}} </a>
                              </td>
                              <td class="px-2">
                                {{item.type}}
                              </td>
                                <td class="px-2">
                                {{item.formatted_date_sent}} {{item.formatted_time}}
                              </td>
                              <td class="pr-0 text-center">
                                <template>
                                  <b-dropdown
                                      size="sm"
                                      variant="link"
                                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                      no-caret
                                      right
                                      no-flip
                                  >
                                    <template v-slot:button-content>
                                      <i class="ki ki-bold-more-hor"></i>
                                    </template>
                                    <!--begin::Navigation-->
                                    <div class="navi navi-hover min-w-md-250px">
                                      <b-dropdown-text tag="div" class="navi-item" v-if="!item.is_viewed">
                                        <a @click="markAsViewed(item.id, item.type)" class="navi-link">
                                          <span class="navi-icon">
                                             <i class="mdi mdi-eye"></i>
                                          </span>
                                          <span class="navi-text">Set as read</span>
                                        </a>
                                      </b-dropdown-text>
                                      <b-dropdown-text tag="div" class="navi-item" v-if="item.is_viewed">
                                        <a @click="markAsNotViewed(item.id)" class="navi-link">
                                          <span class="navi-icon">
                                             <i class="mdi mdi-eye-off"></i>
                                          </span>
                                          <span class="navi-text">Set as unread</span>
                                        </a>
                                      </b-dropdown-text>
                                      <b-dropdown-text tag="div" class="navi-item" v-if="item.type == 'note'">
                                        <a @click="viewNote(item)" class="navi-link">
                                          <span class="navi-icon">
                                             <i class="mdi mdi-note"></i>
                                          </span>
                                          <span class="navi-text">View note</span>
                                        </a>
                                      </b-dropdown-text>
                                      <b-dropdown-text tag="div" class="navi-item">
                                        <a @click="deleteNotification(item.id)" class="navi-link">
                                          <span class="navi-icon">
                                             <i class="mdi mdi-delete"></i>
                                          </span>
                                          <span class="navi-text">Delete</span>
                                        </a>
                                      </b-dropdown-text>
                                    </div>
                                    <!--end::Navigation-->
                                  </b-dropdown>
                                </template>
                              </td>
                            </tr>
                            <tr v-if="notifications.length == 0">
                              <td colspan="5" class="text-center">
                                <strong>No items added</strong>
                              </td>
                            </tr>
                          </template>
                          </tbody>
                        </table>

                        <b-pagination :disabled="loading"
                              v-if="notifications.length > 0"
                              class="pull-right mt-7"
                              @input="getAllNotifications"
                              v-model="page"
                              :total-rows="total"
                              :per-page="perPage"
                              first-number
                              last-number
                        ></b-pagination>
                      </div>
<!--                        <v-alert    -->
<!--                            v-if="!loading"-->
<!--                            v-for="(item, index) in notifications"-->
<!--                            :key="index"-->
<!--                            elevation="0"-->
<!--                            class="border mx-4"-->
<!--                            :icon="item.score_id == null ? 'mdi-file' : 'mdi-information'"-->
<!--                            :color="!item.is_viewed  ? 'blue-grey' : 'black'"-->
<!--                            text-->
<!--                        >   -->
<!--                            <div class="font-size-h6">-->
<!--                                -->
<!--                            </div>-->
<!--                            <span><v-icon color="blue">fa fa-clock</v-icon> {{item.formatted_date_sent}} {{item.formatted_time}} </span>-->
<!--                        </v-alert>-->

<!--                        <b-pagination-->
<!--                            v-if="notifications.length > 0"-->
<!--                            class="pull-right mt-7 mx-4"-->
<!--                            @input="getAllNotifications"-->
<!--                            v-model="page"-->
<!--                            :total-rows="total"-->
<!--                            :per-page="perPage"-->
<!--                            first-number-->
<!--                            last-number-->
<!--                        ></b-pagination>-->
                    </v-card-text>
                </v-card>
            </div>

            <!-- email preview -->
            <v-dialog
                v-model="dialog"
                max-width="800"
            >
                <v-card>
                    <v-toolbar dark>
                        <v-card-title>
                            <span>Email Preview</span>
                            <hr>
                        </v-card-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="closeDialog">
                                <i class="fa fa-close"></i>
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12" class="mt-8">
                                    <div v-html="emailContent"></div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>

        </div>
    </v-app>
</template>

<script>
import UserNotificationService from "@/services/user-notification/UserNotificationService";

const userNotification = new UserNotificationService();

export default {
    data(){
        return {
            notifications: '',
            loading:false,
            total: null,
            perPage: null,
            page: null,
            search:{
                start_date: '',
                end_date: '',
            },
            menuStartDate: false,
            menuEndDate: false,
            currentUser: {},
            dialog: false,
            emailContent: '',
        }
    },
    methods:{
        getAllNotifications(){
            this.loading = true;
            userNotification
            .getAllAdminNotifications(this.search, this.page, this.currentUser.id)
            .then((response) => {
                this.notifications=response.data.data;
                this.page = response.data.meta.current_page;
                this.total = response.data.meta.total;
                this.perPage = response.data.meta.per_page;
                this.loading = false;
            })
            .catch((err) => {
                this.loading = false;
            })
        },
        getCurrentUser() {
            this.currentUser =  this.$store.getters.currentUser;
        },
        emailPreview(item){
          if(!item.is_viewed) {
            userNotification
                .markAsViewed(item.id).then(() => {
              item.is_viewed = 1
            }).catch(() => {});
          }
            this.dialog = true;
            this.emailContent = item.content;
        },
        viewNote(item) {
          if(!item.is_viewed) {
            userNotification
                .markAsViewed(item.id).then(() => {
              item.is_viewed = 1
            }).catch(() => {});
          }
          if(item.candidate_id && item.candidate_exam_key) {
            this.$tabs.open('/candidate/summary/'+item.candidate_exam_key);
          }
          else {
            this.$tabs.open({
              name: "accountholder-edit",
              params: { accountholderId: item.user_id, tabIndex: 1 }
            });
          }
        },
        closeDialog(){
            this.dialog = false;
        },
        markAsNotViewed(notificationId){
          userNotification
              .markAsNotViewed(notificationId).then(respone => {
                this.$snotify.success("Set as unread successfully");
                this.getAllNotifications();
          }).catch(() => {});
        },
        markAsViewed(notificationId){
          userNotification
              .markAsViewed(notificationId).then(respone => {
                this.$snotify.success("Set as read successfully");
                this.getAllNotifications();
          }).catch(() => {});
        },

        viewNotification(notificationId, type){
          this.getAllNotifications();
          if(type == 'print'){
            this.$router.push({
              name: "print-job-queue",
              params: {userNotificationId: notificationId}
            });
          }
          else if(type == 'note'){
            userNotification
                .markAsViewed(notificationId);

            this.$router.push({
              name: "notifications",
            });
          }
          else{
            userNotification
                .readNotification(notificationId);
          }
        },
        deleteNotification(notificationId){
          this.$confirm({
            message: `Are you sure? `,
            button: {
              no: "No",
              yes: "Yes",
            },
            callback: (confirm) => {
              if (confirm) {
                userNotification
                .delete(notificationId)
                .then((response) => {
                  this.getAllNotifications();
                  this.$snotify.success("Notification deleted");
                })
                .catch((err) => {
                });
              }
            },
          });
        }
    },
    mounted(){
        this.getCurrentUser();
        this.getAllNotifications();
    }
}
</script>
